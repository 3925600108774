/* Mixins */
#report-chart-v2-component .jtl-table-query-component {
  width: calc(100% - 76px);
  display: inline-block;
}
#report-chart-v2-component .report-chart-query-search {
  float: right;
}
#report-chart-v2-component .report-content {
  margin-bottom: 16px;
}
#report-chart-v2-component .report-content .ant-card-head {
  border-bottom: none;
}
#report-chart-v2-component .report-chart-extra-left .ant-card-extra {
  margin-left: 0;
}
#report-chart-v2-component .parking-gauge-chart-content {
  height: 300px;
  background-color: #fff;
}
#report-chart-v2-component .parking-gauge-chart-content .parking-title {
  padding-right: 8px;
  border-right: solid 1px #EDEEF0;
  font-size: 14px;
  font-weight: bold;
}
#report-chart-v2-component .parking-gauge-chart-content .parking-count {
  font-size: 14px;
  font-weight: bold;
}
#report-chart-v2-component .parking-gauge-chart-content .parking-desc {
  font-size: 12px;
}
#report-chart-v2-component .parking-gauge-chart-content .parking-gauge-chart-text-wrapper {
  width: 100px;
  margin: 24px auto 0 auto;
}